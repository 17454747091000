/* eslint-disable @typescript-eslint/no-var-requires */

const fs = require('fs');
const path = require('path');
const mime = require('mime');
import { IDocumentScanService } from './IDocumentScanService';

const allowedExtensions = /(\.jpg|\.jpeg|\.png|\.gif|\.doc|\.docx|\.rtf|\.xls|\.xlsx|\.xlsm|\.ppt|\.pptx|\.pdf|\.txt)$/i;

class ElectronDocumentScanService implements IDocumentScanService {
  loadDocuments(dirPath: string) {
    const files: File[] = [];
    const fileNames = this.clearHiddenFiles(fs.readdirSync(dirPath));

    fileNames.forEach((fileName: string) => {
      if (path.extname(fileName) != '' && allowedExtensions.exec(fileName)) {
        const data = fs.readFileSync(path.resolve(dirPath, fileName));
        const blob = new Blob([data]);

        const mimeType = mime.lookup(fileName);
        const type = mimeType.includes('officedocument') ? 'application/octet-stream' : mimeType;

        files.push(new File([blob], fileName, { type }));
      }
    });

    return files;
  }

  moveLoadedDocuments(dirPath: string) {
    const fileNames = this.clearHiddenFiles(fs.readdirSync(dirPath));
    const folderPath = path.resolve(dirPath, 'loaded');
    const lockedFileNames: string[] = [];

    if (!fs.existsSync(folderPath)) {
      fs.mkdirSync(folderPath);
    }

    fileNames.forEach((fileName: string) => {
      if (path.extname(fileName) != '' && allowedExtensions.exec(fileName)) {
        try {
          fs.renameSync(path.resolve(dirPath, fileName), path.resolve(folderPath, fileName));
        } catch {
          lockedFileNames.push(fileName);
        }
      }
    });

    return lockedFileNames;
  }

  clearFolder(dirPath: string) {
    const fileNames = this.clearHiddenFiles(fs.readdirSync(dirPath));
    const lockedFileNames: string[] = [];

    fileNames.forEach((fileName: string) => {
      if (path.extname(fileName) != '' && allowedExtensions.exec(fileName)) {
        try {
          fs.unlinkSync(path.resolve(dirPath, fileName));
        } catch {
          lockedFileNames.push(fileName);
        }
      }
    });

    return lockedFileNames;
  }

  clearHiddenFiles(fileNames: string[]) {
    return fileNames.filter((file) => !file.startsWith('~'));
  }

  checkIsPathExists(dirPath: string) {
    return fs.existsSync(dirPath);
  }
}

export const documentScanService = new ElectronDocumentScanService();
