import { IFolderBrowserDialogService } from './IFolderBrowserDialogService';

// eslint-disable-next-line @typescript-eslint/no-var-requires
const { dialog } = require('electron').remote;

class ElectronFolderBrowserDialogService implements IFolderBrowserDialogService {
  canOpen(): boolean {
    return true;
  }
  open(): string {
    const paths = dialog.showOpenDialogSync({
      properties: ['openDirectory', 'createDirectory'],
    });
    return paths && paths.length > 0 ? paths[0] : '';
  }
}

export const folderBrowserDialogService = new ElectronFolderBrowserDialogService();
