/* eslint-disable @typescript-eslint/no-var-requires */
import { Nullable } from '@/Types';
import { getWebContents } from '@/Electron/ElectronImports';
import { IPrinterInfo, IPrintService, PrinterSettings, PrintResult } from './IPrintService';
import { DocumentTemplateApi, PrinterSettingsResponse, PrintUnionDocumentRequest } from '@/Api/DocumentTemplates';
import { print as pdfPrint } from 'pdf-to-printer';
import { BinaryResponse } from '@/Api';
import { PrintOptions } from 'pdf-to-printer/dist/print/print';
import { AutomaticallyGeneratedDocumentType } from '@/Enums/AutomaticallyGeneratedDocumentType';

const path = require('path');
const fs = require('fs');
const os = require('os');

class ElectronPrintService implements IPrintService {
  getPrinters(): IPrinterInfo[] {
    return getWebContents()?.getPrinters() ?? [];
  }

  getDefaultPrinter(): Nullable<IPrinterInfo> {
    const printer = this.getPrinters().find((x) => x.isDefault);
    return printer ? printer : null;
  }

  print(
    guid: string,
    orderIds: number[],
    printerSettings: PrinterSettings,
    callback?: (params: number[]) => void,
  ): Promise<PrintResult> {
    return DocumentTemplateApi.printerSettings(guid)
      .then((printerSettingsResponse) => {
        const request: PrintUnionDocumentRequest = {
          orderIds: orderIds,
          copyCount: printerSettings.copyCount,
          isSortWhenPrinting: printerSettings.isSortWhenPrinting,
          automaticallyGeneratedDocumentType: printerSettings.automaticallyGeneratedDocumentType,
        };

        return DocumentTemplateApi.printUnionDocument(guid, request)
          .then((response) => {
            callback?.(orderIds);
            this.saveFilesOnDisk(guid, orderIds, printerSettings);

            if (printerSettings.isPreview) {
              return this.openInWindowResponse(response);
            }
            return this.printDirect(response, printerSettings, printerSettingsResponse);
          })
          .catch((error) => {
            console.log('error', error);
            return PrintResult.ErrorDownloading;
          });
      })
      .catch((error) => {
        console.log('printerSettings error', error);
        return PrintResult.ErrorPrint;
      });
  }

  printDocument(documentBase64: string, isPrintPreview: boolean, assignPrinter: string): Promise<PrintResult> {
    if (isPrintPreview) {
      const result = this.openInWindowBase64(documentBase64);
      if (result === PrintResult.Success) {
        return Promise.resolve(PrintResult.Success);
      }

      return Promise.reject(result);
    }

    const printer = this.getDefaultPrinter();

    if (printer == null) {
      return Promise.reject(PrintResult.ErrorPrint);
    }

    const printerSettings: PrinterSettings = {
      printerName: assignPrinter ?? printer.name,
      isPreview: isPrintPreview,
      isSaveWhenPrinting: false,
      pathSaveWhenPrinting: '',
      copyCount: 1,
      isSortWhenPrinting: false,
      automaticallyGeneratedDocumentType: AutomaticallyGeneratedDocumentType.DPRNT,
    };

    const response: BinaryResponse = {
      contentType: '',
      fileName: '',
      data: Buffer.from(documentBase64, 'base64'),
    };

    return this.printDirect(response, printerSettings);
  }

  openInWindowBase64(documentBase64: string) {
    window.open('data:application/pdf;base64,' + documentBase64);
    return PrintResult.Success;
  }

  openInWindowResponse(response: BinaryResponse) {
    const blob = new Blob([response.data], { type: 'application/pdf' });
    const url = window.URL.createObjectURL(blob);

    window.open(url);

    setTimeout(() => {
      window.URL.revokeObjectURL(url);
    }, 30000);

    return PrintResult.Success;
  }

  printDirect(
    response: BinaryResponse,
    printerSettings: PrinterSettings,
    templatePrinterSettings?: PrinterSettingsResponse,
  ): Promise<PrintResult> {
    const pdfPath = this.saveFile(response, printerSettings);
    if (pdfPath === '') {
      return Promise.reject<PrintResult.ErrorSaving>();
    }

    const options: PrintOptions = {
      printer: printerSettings.printerName,
      orientation: templatePrinterSettings?.isLandscape ? 'landscape' : undefined,
      scale: templatePrinterSettings?.scale?.toLocaleLowerCase() ?? undefined,
      silent: true,
      //paperSize: templatePrinterSettings?.paperKind ?? undefined
    };

    console.log('print direct:', options);

    return pdfPrint(pdfPath, options)
      .then(() => {
        this.deleteFile(pdfPath);
        return PrintResult.Success;
      })
      .catch((error) => {
        console.log('print temp file error:', error);
        this.deleteFile(pdfPath);
        return PrintResult.ErrorPrint;
      });
  }

  saveFilesOnDisk(guid: string, orderIds: number[], printerSettings: PrinterSettings): void {
    if (printerSettings.isSaveWhenPrinting) {
      console.log('save on disk orders:', orderIds);
      orderIds.forEach((id) => {
        DocumentTemplateApi.printDocument(guid, id).then((response) => {
          console.log('save on disk order:', id);
          this.saveFile(response, printerSettings);
        });
      });
    }
  }

  saveFile(response: BinaryResponse, printerSettings: PrinterSettings): string {
    const data = response.data;

    const folderPath = this.getFolderPath(printerSettings);

    console.log('save folderPath:', folderPath);

    this.createFolderIfNeed(folderPath);

    const fileName = this.getFileName(response);
    const pdfPathEthalon = path.join(folderPath, fileName);

    let index = 1;
    let pdfPath = pdfPathEthalon;
    while (fs.existsSync(pdfPath)) {
      pdfPath = pdfPathEthalon.replace('.pdf', ' (' + index + ').pdf');
      index++;
    }

    fs.writeFileSync(pdfPath, data, 'binary', (error: any) => {
      if (error) {
        pdfPath = '';
        console.log(`Wrote PDF with error ${error}`);
      }
    });

    console.log('pdfPath:', pdfPath);

    return pdfPath;
  }

  deleteFile(pdfPath: string): void {
    if (fs.existsSync(pdfPath)) {
      console.log('delete file:', pdfPath);

      fs.unlink(pdfPath, (error: any) => {
        if (error) {
          console.log('delete temp file error:', error);
        }
      });
    }
  }

  getFolderPath(printerSettings: PrinterSettings): string {
    return printerSettings.isSaveWhenPrinting &&
      printerSettings.pathSaveWhenPrinting &&
      printerSettings.pathSaveWhenPrinting !== ''
      ? printerSettings.pathSaveWhenPrinting
      : os.tmpdir();
  }

  getFileName(response: BinaryResponse): string {
    return response.fileName === '' ? Date.now().toString() + '.pdf' : response.fileName.replace(/"/g, '');
  }

  createFolderIfNeed(folderPath: string): void {
    if (!fs.existsSync(folderPath)) {
      fs.mkdirSync(folderPath, {
        recursive: true,
      });
    }
  }
}

export const printService = new ElectronPrintService();
