/* eslint-disable @typescript-eslint/no-var-requires */
import { IDocumentPrinterInfo, IDocumentStoreService } from './IDocumentStoreService';
import { Nullable } from '@/Types';
import { remote } from 'electron';
const fs = require('fs');
const path = require('path');

class ElectronDocumentStoreService implements IDocumentStoreService {
  private _fileName: string;
  private _printers: IDocumentPrinterInfo[];
  private _pathToFolder: string;
  private _pathToFile: string;

  constructor() {
    this._fileName = 'printers.json';
    this._pathToFolder = this.getPathToFolder();
    this._pathToFile = this.getPathToFile();
    this._printers = this.readFile();
  }

  upToDateByPrinters(names: string[]): void {
    try {
      this._printers = this.readFile();

      if (names) {
        this._printers = this._printers.filter((x) => names.findIndex((y) => y === x.printerName) > -1);
      }
    } catch (e) {
      console.log('Up to date printer.json!', e);
    }
  }

  upToDateByDocuments(documentGuids: string[]): void {
    try {
      this._printers = this.readFile();

      if (documentGuids) {
        this._printers = this._printers.filter((x) => documentGuids.findIndex((y) => y === x.documentGuid) > -1);
      }
    } catch (e) {
      console.dir('Up to date printer.json!', e);
    }
  }

  addOrUpdate(printerInfo: IDocumentPrinterInfo): void {
    try {
      const index = this._printers.findIndex((x) => x.documentGuid == printerInfo.documentGuid);
      if (index == -1) {
        this._printers.unshift(printerInfo);
      } else {
        this._printers = this._printers.map((x) => {
          return x.documentGuid === printerInfo.documentGuid ? printerInfo : x;
        });
      }

      this.saveFile(this._printers);
    } catch (e) {
      console.log('Failed to save printers.json!', e);
    }
  }

  removeByDocumentGuid(documentGuid: string): void {
    this._printers = this._printers.filter((x) => x.documentGuid !== documentGuid);
    this.saveFile(this._printers);
  }

  getByDocumentGuid(documentGuid: string): Nullable<IDocumentPrinterInfo> {
    try {
      const printer = this._printers.find((x) => x.documentGuid === documentGuid);
      return printer ? printer : null;
    } catch (e) {
      console.log('Failed to get printer!', e);
    }
    return null;
  }

  getPathToFolder(): string {
    const appData = remote.app.getPath('appData');
    return path.join(appData, 'ShipCheaper');
  }

  getPathToFile(): string {
    return path.join(this.getPathToFolder(), this._fileName);
  }

  readFile(): IDocumentPrinterInfo[] {
    try {
      if (!fs.existsSync(this._pathToFile)) {
        return [];
      }

      const json = fs.readFileSync(this._pathToFile, 'utf-8');
      if (json) {
        return JSON.parse(json) as IDocumentPrinterInfo[];
      }
    } catch (e) {
      console.log('File printers.json read', e);
    }

    return [];
  }

  saveFile(printers: IDocumentPrinterInfo[]): void {
    try {
      if (!fs.existsSync(this._pathToFolder)) {
        fs.mkdirSync(this._pathToFolder);
      }

      const json = JSON.stringify(printers);
      fs.writeFileSync(this._pathToFile, json, 'utf-8');
    } catch (e) {
      console.log('Failed to save printers.json!', e);
    }
  }
}

export const documentStoreService = new ElectronDocumentStoreService();
